<template>
  <BasicModal
    title="修改设备"
    width="500px"
    :visible="visible"
    :confirmLoading="confirmLoading"
    @ok="ok"
    @cancel="cancel"
  >
    <BasicForm
      ref="formRef"
      :labelCol="{ span: 5 }"
      :formItemsMap="formItemsMap"
      v-model:formData="formData"
    ></BasicForm>
  </BasicModal>
</template>

<script setup>
import { defineProps, ref, defineEmits, reactive, onMounted } from "vue";
import { message } from "ant-design-vue";
import { apiDevice } from "@/api/centerMonitor/projectMonitor";
import { apiProject, apiSpace } from "@/api/IoT/project.js";

const props = defineProps({
  detail: {
    type: Object,
    default: null,
  },
  visible: {
    type: Boolean,
    default: false,
  },
});
const emit = defineEmits(["change", "update:visible", "success"]);
const formRef = ref(null);

onMounted(async () => {
  await initFormItems();
  initFormData();
});

const formItemsMap = reactive({
  projectId: {
    label: "所属项目",
    prop: "projectId",
    type: "select",
    options: [],
    fieldNames: {
      label: "name",
      value: "id",
    },
    disabled: true,
  },
  productName: {
    label: "所属产品",
    prop: "productName",
    type: "input",
    disabled: true,
    placeholder: " ",
  },
  name: {
    label: "设备名称",
    prop: "name",
    type: "input",
    required: true,
    requiredMessage: "请输入",
  },
  code: {
    label: "设备编码",
    prop: "code",
    type: "input",
    required: true,
    requiredMessage: "请输入",
  },
  sourceDeviceId: {
    label: "设备外部ID",
    prop: "sourceDeviceId",
    type: "input",
    required: true,
    requiredMessage: "请输入",
  },
  bizAreaId: {
    label: "所属空间",
    prop: "bizAreaId",
    type: "treeSelect",
    options: [],
    fieldNames: {
      label: "spaceName",
      value: "spaceId",
    },
    required: true,
    requiredMessage: "请选择",
  },
  locationDesc: {
    label: "设备位置",
    prop: "locationDesc",
    type: "input",
  },
  deviceDesc: {
    label: "产品描述",
    prop: "deviceDesc",
    type: "textarea",
  },
});
const initFormItems = async () => {
  apiProject
    .page({
      pageNo: 1,
      pageSize: 100,
    })
    .then((res) => {
      formItemsMap.projectId.options = res.result.records;
    });
  initSpaceOptions();
  initParameterList();
};
const initSpaceOptions = async () => {
  if (!props.detail.projectId) {
    formData.bizAreaId = undefined;
    formItemsMap.bizAreaId.options = [];
    return;
  }
  try {
    const res = await apiSpace.list(props.detail.projectId);
    formItemsMap.bizAreaId.options = res.result;
  } catch (error) {
    console.log(error);
  }
};
const initParameterList = () => {
  apiDevice.parameterList(props.detail.productId).then((res) => {
    formatFormItems(res.result);
  });
};
const formTypeMap = {
  "01": "input",
  "02": "input",
  "03": "input",
  "04": "select",
  "05": "select",
};
let otherAttrList = [];
const formatFormItems = (data) => {
  otherAttrList = data;
  data.forEach((item) => {
    const { functionName, identifier, dataTpe, valueDescription } = item;
    let _item = {
      label: functionName,
      prop: identifier,
      type: formTypeMap[dataTpe],
      options: valueDescription.map((option) => ({
        label: option.value,
        value: option.key,
      })),
    };
    if (dataTpe === "02" || dataTpe === "03") {
      _item.inputType = "number";
    }
    formItemsMap[identifier] = _item;
  });
};

const formData = reactive({
  projectId: undefined,
  productName: "",
  name: "",
  code: "",
  sourceDeviceId: "",
  bizAreaId: undefined,
  locationDesc: "",
  deviceDesc: "",
});
const initFormData = async () => {
  if (!props.detail) return;
  for (const key in formData) {
    if (key === "bizAreaId") {
      formData[key] = Number(props.detail[key]);
    } else {
      formData[key] = props.detail[key];
    }
  }
  props.detail.deviceParameters.forEach((item) => {
    formData[item.identifier] = item.value;
  });
};

const confirmLoading = ref(false);
const ok = async () => {
  try {
    await formRef.value.formRef.validateFields();
    const params = {
      id: props.detail.id,
      bizDeviceId: props.detail.bizDeviceId,
      ...formData,
      deviceParameters: otherAttrList.map((item) => ({
        identifier: item.identifier,
        value: formData[item.identifier],
        functionName: item.functionName,
      })),
    };
    confirmLoading.value = true;
    await apiDevice.edit(params);
    message.success(`修改成功`);
    emit("update:visible", false);
    emit("success");
    confirmLoading.value = false;
  } catch (errorInfo) {
    confirmLoading.value = false;
    console.log("Failed:", errorInfo);
  }
};
const cancel = () => {
  emit("update:visible", false);
};
</script>

<style lang="less" scoped></style>
