import { ref, computed, onActivated } from "vue";
import { useRouter, useRoute, onBeforeRouteUpdate } from "vue-router";

export function useTabState(tabMap) {
  const router = useRouter();
  const route = useRoute();
  const activeKey = ref(
    route?.query?.componentTabKey ?? Object.keys(tabMap)[0] ?? ""
  );

  const currentComponent = computed(() => {
    return tabMap[activeKey.value]?.component ?? null;
  });

  router &&
    onBeforeRouteUpdate((to) => {
      initActive(to);
    });
  onActivated(() => {
    initActive(route);
  });

  function initActive(route) {
    activeKey.value =
      route?.query?.componentTabKey ?? Object.keys(tabMap)[0] ?? "";
  }
  function changeTab(value) {
    router &&
      router.replace({
        path: route.fullPath,
        query: {
          ...route.query,
          componentTabKey: value,
        },
      });
  }
  return {
    activeKey,
    currentComponent,
    changeTab,
    initActive,
  };
}
