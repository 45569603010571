<template>
  <div class="product-device-search">
    <CategorySearch :source="filterSource" @change="search" />
  </div>
  <BasicTable ref="tableRef" v-bind="tableConfig" @page-change="pageChange">
    <template #slotName="{ row }">
      <router-link
        :to="{
          path: `/IoTManage/device/detail/${row.bizDeviceId}`,
          state: { tabName: row.name },
        }"
        >{{ row.name }}</router-link
      >
    </template>
  </BasicTable>
  <DeviceEditModal
    v-if="modalVisible"
    v-model:visible="modalVisible"
    :projectId="projectId"
    :detail="currentRow"
    @success="editSuccess"
  ></DeviceEditModal>
</template>

<script setup>
import { defineProps, onMounted, ref, reactive, watch } from "vue";
import CategorySearch from "@/components/business/CategorySearch/categorySearch.vue";
import { apiDevice } from "@/api/centerMonitor/projectMonitor.js";
import { BasicTable } from "@/components/basic/table";
import DeviceEditModal from "./components/DeviceEditModal";

const props = defineProps({
  projectId: {
    type: String,
    default: "",
  },
});
watch(
  () => props.projectId,
  () => {
    initTableData();
  }
);

const filterSource = ref([
  {
    label: "设备名称",
    type: "input",
    bindKey: "name",
    placeholder: "请输入",
  },
]);
let searchData = {};
const search = (data) => {
  searchData = data;
  tableRef.value.paginationProps.current = 1;
  initTableData();
};

const tableRef = ref();
const tableConfig = reactive({
  showIndex: false,
  showSelect: false,
  pagination: true,
  isPageBottom: true,
  remote: false,
  loading: false,
  scroll: {
    scrollToFirstRowOnChange: true,
    y: "calc(100vh - 320px)",
  },
  columns: [
    {
      title: "设备ID",
      type: "text",
      key: "bizDeviceId",
      width: 150,
    },
    {
      title: "设备名称",
      type: "customize",
      slotName: "slotName",
      width: 180,
    },
    {
      title: "设备编码",
      type: "text",
      key: "code",
      width: 150,
    },
    {
      title: "所属空间",
      type: "text",
      key: "areaName",
      width: 150,
    },
    {
      title: "所属产品",
      type: "text",
      key: "productName",
      width: 150,
    },
  ],
  action: {
    title: "操作",
    width: 60,
    fixed: "right",
    items: [
      {
        type: "icon",
        icon: "FormOutlined",
        iconTip: "修改",
        onClick: (row) => {
          showModal(row);
        },
      },
    ],
  },
});
const initTableData = async () => {
  if (!props.projectId) return;
  const { current, pageSize } = tableRef.value.paginationProps;
  const params = {
    projectId: props.projectId,
    pageNo: current,
    pageSize,
    ...searchData,
  };
  tableConfig.loading = true;
  try {
    const res = await apiDevice.page(params);
    const { records, total } = res?.result ?? {};
    tableConfig.loading = false;
    tableRef.value.dataSourceRef = records;
    tableRef.value.paginationProps.total = total;
  } catch (error) {
    tableConfig.loading = false;
    console.log(error);
  }
};
const pageChange = (pageIndex) => {
  tableRef.value.paginationProps.current = pageIndex;
  initTableData();
};

const modalVisible = ref(false);
const currentRow = ref(null);
const showModal = (row) => {
  currentRow.value = row;
  modalVisible.value = true;
};
const editSuccess = async () => {
  initTableData();
};

onMounted(() => {
  tableRef.value.paginationProps.pageSize = 20;
  initTableData();
});
</script>

<style lang="less" scoped>
.product-device-search {
  padding: 16px 0;
  ::v-deep .category-search {
    width: 600px;
    margin-bottom: 0;
  }
  ::v-deep .container_wrap {
    width: 560px !important;
    overflow-x: hidden !important;
  }
}
</style>
