<template>
  <div class="project-detail-space">
    <div class="project-detail-space-header">
      <div class="project-detail-space-header-title">
        <span class="project-detail-space-header-line"></span>
        <span>空间列表</span>
      </div>
      <div class="project-detail-space-header-handle">
        <a-button type="primary" @click="showModal(null)">添加</a-button>
      </div>
    </div>
    <BasicTable ref="tableRef" v-bind="tableConfig"> </BasicTable>
  </div>
  <SpaceEditModal
    v-if="modalVisible"
    v-model:visible="modalVisible"
    :projectId="projectId"
    :detail="currentDetail"
    @success="editSuccess"
  ></SpaceEditModal>
</template>

<script setup>
import { defineProps, ref, reactive, onMounted, watch } from "vue";
import { Modal, message } from "ant-design-vue";
import { apiSpace } from "@/api/centerMonitor/projectMonitor";
import { BasicTable } from "@/components/basic/table";
import SpaceEditModal from "./components/SpaceEditModal";

const tableRef = ref();

const props = defineProps({
  projectId: {
    type: String,
    default: "",
  },
});

watch(
  () => props.projectId,
  () => {
    initTableData();
  }
);
onMounted(() => {
  initTableData();
});

const tableConfig = reactive({
  rowKey: "spaceId",
  showIndex: false,
  showSelect: false,
  pagination: false,
  isPageBottom: true,
  remote: false,
  loading: false,
  defaultExpandAllRows: true,
  isFinish: true,
  scroll: {
    scrollToFirstRowOnChange: true,
    y: "calc(100vh - 280px)",
  },
  columns: [
    {
      title: "名称",
      type: "text",
      key: "spaceName",
      width: 180,
    },
    {
      title: "类型",
      type: "text",
      key: "spaceTypeName",
      width: 80,
    },
    {
      title: "建筑面积（㎡）",
      type: "text",
      key: "spaceProportion",
      width: 150,
    },
    {
      title: "备注",
      type: "text",
      key: "spaceDescription",
      width: 300,
      ellipsis: true,
    },
  ],
  action: {
    title: "操作",
    width: 70,
    fixed: "right",
    items: [
      {
        type: "icon",
        icon: "FormOutlined",
        iconTip: "修改",
        onClick: (row) => {
          showModal(row);
        },
      },
      {
        type: "icon",
        icon: "DeleteOutlined",
        iconTip: "删除",
        visibleFn: (row) => !row.children?.length && row.parentId !== 0,
        onClick: (row) => {
          Modal.confirm({
            title: "提示",
            content: "是否删除该条数据？",
            okType: "danger",
            onOk: () => {
              return new Promise((resolve, reject) => {
                apiSpace
                  .delete(row.spaceId)
                  .then(() => {
                    message.success("删除成功");
                    initTableData();
                    resolve();
                  })
                  .catch(() => {
                    reject();
                  });
              });
            },
            onCancel() {
              console.log("Cancel");
            },
          });
        },
      },
    ],
  },
});
const initTableData = async () => {
  if (!props.projectId) {
    return;
  }
  tableConfig.loading = true;
  tableConfig.isFinish = false;
  try {
    const { result } = await apiSpace.list(props.projectId);
    tableConfig.isFinish = true;
    tableConfig.loading = false;
    tableRef.value.dataSourceRef = result;
  } catch (error) {
    tableConfig.isFinish = true;
    tableConfig.loading = false;
    console.log(error);
  }
};

const modalVisible = ref(false);
const currentDetail = ref(false);
const showModal = (row) => {
  currentDetail.value = row;
  modalVisible.value = true;
};
const editSuccess = () => {
  initTableData();
};
</script>

<style lang="less" scoped>
.project-detail-space {
  height: 100%;
  &-header {
    display: flex;
    justify-content: space-between;
    &-title {
      display: flex;
      align-items: center;
      gap: 10px;
      height: 56px;
      font-size: 16px;
    }
    &-line {
      width: 3px;
      height: 16px;
      background: #0256ff;
    }
    &-handle {
      display: flex;
      align-items: center;
      gap: 8px;
    }
  }
}
</style>
