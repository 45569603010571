<template>
  <TwoColumnsLayout leftWidth="240px">
    <template #left>
      <ProjectDetailLeft
        :loading="detailLoading"
        :data="detail"
        @edit="onEdit"
      ></ProjectDetailLeft>
    </template>
    <template #right>
      <ComponentTabs
        :tabMap="tabMap"
        v-model:activeKey="activeKey"
        @change="changeTab"
      >
        <component :is="currentComponent" :projectId="projectId"></component>
      </ComponentTabs>
    </template>
  </TwoColumnsLayout>
  <ProjectEditModal
    v-if="modalVisible"
    v-model:visible="modalVisible"
    :detail="detail"
    @success="editSuccess"
  ></ProjectEditModal>
</template>

<script setup>
import { onActivated, ref } from "vue";
import ProjectDetailLeft from "@/views/IoTManage/projectManage/components/ProjectDetailLeft.vue";
import ProjectEditModal from "./components/ProjectEditModal";
import { useRoute } from "vue-router";
import { apiProject } from "@/api/IoT/project.js";
import ProjectDetailSpace from "./ProjectDetailSpace";
import ProjectDetailDevice from "./ProjectDetailDevice";
import { useTabState } from "@/components/basic/tabs/hooks/useTabState";
import { onBeforeRouteUpdate } from "vue-router";

const route = useRoute();

onBeforeRouteUpdate((to, from) => {
  if (to.path !== from.path) {
    projectId.value = to.params?.projectId ?? "";
    initDetail();
  }
});
onActivated(() => {
  projectId.value = route.params?.projectId ?? "";
  initDetail();
});

const projectId = ref("");
const detailLoading = ref(false);
const detail = ref({});
const initDetail = async () => {
  if (!projectId.value) return;
  detailLoading.value = true;
  try {
    const res = await apiProject.detail(projectId.value);
    detail.value = res.result;
  } catch (error) {
    console.log(error, "详情");
  }
  detailLoading.value = false;
};

const modalVisible = ref(false);
const onEdit = () => {
  modalVisible.value = true;
};
const editSuccess = () => {
  initDetail();
};

const tabMap = {
  device: {
    label: "设备管理",
    component: ProjectDetailDevice,
  },
  space: {
    label: "空间管理",
    component: ProjectDetailSpace,
  },
};
const { currentComponent, changeTab, activeKey } = useTabState(tabMap);
</script>

<style lang="less" scoped>
.project-detail-content {
  display: flex;
  flex-direction: column;
  padding: 0 20px 20px;
  &-tabs {
    font-size: 16px;
  }
  &-tabItem {
    flex: 1;
  }
}
</style>
