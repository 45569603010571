<template>
  <LeftDetail
    width="240px"
    :loading="loading"
    :hasData="hasData"
    @edit="onEdit"
  >
    <div class="project-detail-left-content">
      <div class="project-detail-left-base">
        <div class="project-detail-name">{{ data.name }}</div>
        <div class="project-detail-code">{{ data.code }}</div>
        <div class="project-detail-description">
          {{ data.description }}
        </div>
      </div>
      <div class="project-detail-left-status">{{ data.statusName }}</div>
      <div class="project-detail-left-items">
        <div class="project-detail-left-item">
          <div class="project-detail-left-item-label">项目业态</div>
          <div class="project-detail-left-item-value">
            {{ data.bizTypeName }}
          </div>
        </div>
        <div class="project-detail-left-item">
          <div class="project-detail-left-item-label">能耗类型</div>
          <div class="project-detail-left-item-value">
            {{ data.energyTypeName.join("/") }}
          </div>
        </div>
        <div class="project-detail-left-item">
          <div class="project-detail-left-item-label">项目负责人</div>
          <div class="project-detail-left-item-value">{{ data.director }}</div>
        </div>
        <div class="project-detail-left-item">
          <div class="project-detail-left-item-label">负责人电话</div>
          <div class="project-detail-left-item-value">{{ data.mobile }}</div>
        </div>
        <div class="project-detail-left-item">
          <div class="project-detail-left-item-label">建筑面积</div>
          <div class="project-detail-left-item-value">{{ data.area }}㎡</div>
        </div>
        <div class="project-detail-left-item"></div>
      </div>
      <div class="project-detail-left-node">
        <div class="project-detail-left-node-label">所属管理节点</div>
        <div class="project-detail-left-node-value">
          {{ data.parentBizNodeName }}
        </div>
      </div>
      <div class="project-detail-left-address">
        <div class="project-detail-left-address-label">设备位置：</div>
        <div class="project-detail-left-address-value">
          {{ data.addressName }}{{ data.address }}
        </div>
      </div>
      <div class="project-detail-left-address">
        <div class="project-detail-left-node-label">能源子系统：</div>
        <div class="project-detail-left-node-value">
          {{ energySubSystem }}
        </div>
      </div>
      <!-- <MapContainer
        height="136px"
        :value="[data.gdLongitude, data.gdLatitude]"
        :disabled="true"
      ></MapContainer> -->
    </div>
  </LeftDetail>
</template>

<script setup>
import { defineProps, defineEmits, computed } from "vue";

const props = defineProps({
  data: {
    type: Object,
    default: () => ({}),
  },
  loading: {
    type: Boolean,
    default: false,
  },
});
const emit = defineEmits(["edit"]);

const hasData = computed(() => !!props.data?.id ?? false);
const energySubSystem = computed(() => props.data.energySubSystemName.join('、'));

const onEdit = () => {
  emit("edit");
};
</script>

<style lang="less" scoped>
.project-detail-left-base {
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 0 20px 20px;
  font-size: 14px;
  .project-detail-name {
    font-weight: bold;
    font-size: 16px;
    color: #38404c;
  }
  .project-detail-code {
    color: #999999;
  }
  .project-detail-description {
    color: #666666;
  }
}
.project-detail-left-status {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  border-top: 1px solid #e5e6e8;
  border-bottom: 1px solid #e5e6e8;
}
.project-detail-left-items {
  display: flex;
  flex-wrap: wrap;
  .project-detail-left-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 50%;
    height: 60px;
    border-bottom: 1px solid #e5e6e8;
    &:nth-child(2n) {
      border-left: 1px solid #e5e6e8;
    }
    &-label {
      margin-bottom: 8px;
      color: #999999;
      font-size: 12px;
      line-height: 1;
    }
    &-value {
      line-height: 1;
    }
  }
}
.project-detail-left-node {
  text-align: center;
  padding: 12px;
  border-bottom: 1px solid #e5e6e8;
  &-label {
    margin-bottom: 8px;
    color: #999999;
    font-size: 12px;
    line-height: 1;
  }
  &-value {
    line-height: 1;
  }
}
.project-detail-left-address {
  padding: 12px 12px;
  border-bottom: 1px solid #e5e6e8;
  &-label {
    margin-bottom: 8px;
    color: #999999;
    font-size: 12px;
    line-height: 1;
  }
  &-value {
    line-height: 1;
    font-size: 12px;
  }
}
</style>
